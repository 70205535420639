import { getInfo, login as apiLogin, logout } from '@/api/auth';
import { defineStore } from 'pinia';
import {
	getHeaders,
	removeAuthTokens,
	removeUserCantEnterLoginPage,
	setAuthTokens,
	setUserCantEnterLoginPage,
} from '@/utils/auth';
import { useAppStore } from '@/store/app';
import router, { asyncRoutes, resetRouter } from '@/router';
import type { UserState, UserInfo, Module, UserModuleIdentifiers, UserModules } from '@/types/store/user';
import type { RouterRecord } from '@/types/router';
import { notification } from '@/utils/notifications';
import { RouteNames } from '@/types/router';
import i18n from '@/lang';

export enum RouteAccessibility {
	SUCCESS,
	ERROR_AVAILABILITY,
	ERROR_NOT_AUTHORIZED,
}

export const useUserStore = defineStore('user', {
	state: (): UserState => ({
		id: undefined,
		name: '',
		email: '',
		roles: [],
		permissions: [],
		modules: {},
		lang: 'sk',
	}),

	getters: {
		module: (state): (moduleIdString: UserModuleIdentifiers) => Module | undefined => (moduleIdString: UserModuleIdentifiers) => state.modules[moduleIdString],
		areThreeDotsAvailable(state) {
			return (gotResponse: boolean) => {
				if (router.currentRoute.value.meta.root) {
					return state.roles.includes('superadmin');
				}

				return state.permissions.includes('manage locality') && gotResponse;
			};
		},
		isSuperAdmin: state => state.roles.some(role => role === 'superadmin'),
		isRouteAccessible(state) {
			return (to: RouterRecord) => {
				if (to?.meta?.roles?.some(role => !state.roles.includes(role)) ?? to.meta?.cantEnterRoles?.some(role => state.roles.includes(role))) {
					return RouteAccessibility.ERROR_NOT_AUTHORIZED;
				}

				if (to.meta?.id && state.modules[to.meta.id]?.available === false) {
					return RouteAccessibility.ERROR_AVAILABILITY;
				}

				return RouteAccessibility.SUCCESS;
			};
		},
		generateRoutes: state => asyncRoutes
			.map((route => {
				if (!route.meta?.id) {
					return route;
				}

				const userStoreModule = state.modules[route.meta.id] as Module;
				if (!userStoreModule) {
					return route;
				}

				return {
					...route,
					available: userStoreModule.available,
				};
			}))
			.filter(({ meta }) => {
				if (meta?.roles?.length) {
					return meta.roles.some(role => state.roles.includes(role));
				}

				return !meta?.hidden;
			}),
	},

	actions: {
		async login(userInfo: UserInfo) {
			const { email, password } = userInfo;
			// @ts-expect-error
			const { t } = i18n.global;
			try {
				const { access_token, refresh_token } = await apiLogin({ email: email.trim(), password });
				setAuthTokens(access_token, refresh_token);
			} catch (error: any) {
				if (error?.code === 'ERR_NETWORK') {
					notification('error', t('login.internet'));
					return;
				}

				if (error?.response?.status === 429) {
					notification('error', t('errorlog.429'));
				} else {
					notification('error', t('login.invalid_grant'));
				}
			}
		},
		async getInfo() {
			const appStore = useAppStore();
			const { locale, t } = i18n.global;

			try {
				const { data } = await getInfo<{data: { roles: string[]; name: string; lang: 'sk' | 'en'; permissions: string[]; id: number; modules: UserModules; email: string }}>();

				if (!data) {
					return new Error('Verification failed, please Login again.');
				}

				const { roles, name, lang, permissions, id, modules, email } = data;

				setUserCantEnterLoginPage();
				this.roles = roles;
				this.permissions = permissions;
				this.name = name;
				this.email = email;
				this.id = id;
				this.modules = modules;
				this.lang = lang ?? 'sk';
				locale.value = lang ?? 'sk';
				appStore.setLanguage(lang ?? 'sk');
			} catch {
				notification('error', t('errorlog.logoutToken'));
				await this.logout();
				await router.push({ name: RouteNames.LOGIN });
			}
		},
		async logout() {
			const { t } = i18n.global;
			this.roles = [];
			this.modules = {};
			removeUserCantEnterLoginPage();
			const headers = getHeaders();
			removeAuthTokens();
			resetRouter();
			try {
				await logout(headers);
			} catch {
				notification('error', t('errorlog.logout'));
			}
		},
		removeToken() {
			this.roles = [];
			this.modules = {};
			removeAuthTokens();
		},
		// async changeRoles(role: any) {
		// 	return new Promise(async resolve => {
		// 		const permissions = role.permissions.map(permission => permission.name);
		// 		this.roles = [role.name];
		// 		this.permissions = permissions;
		// 		resetRouter();
		//
		// 		// Generate accessible routes map based on roles
		// 		const accessRoutes = await store.dispatch('permission/generateRoutes', { [role.name], permissions });
		//
		// 		// Dynamically add accessible routes
		// 		router.addRoutes(accessRoutes);
		//
		// 		resolve();
		// 	});
		// },
		setInfo(userState: Pick<UserState, 'modules' | 'lang'>) {
			const { lang, modules } = userState;

			this.modules = modules;
			this.lang = lang;
		},
	},
});
